@use "../designSystem/common/sassVars" as *;
@use "../../../node_modules/flickity/dist/flickity.css";
@use '../components/export/carouselNavButtons';

.rules {
	&~h3 {
		font-size: 14px;

		@media (min-width: $scr-sm-min) {
			font-size: 16px;
		}
	}

	&--other {
		.carousel {
			@media (min-width: $scr-sm-min) {
				&::after {
					content: 'flickity';
					display: none;
				}
			}

			@media (max-width: $scr-sm) {
				&::after {
					content: '';
				}
			}
		}

		@media (max-width: $scr-sm) {
			.mobileCarouselContainer {
				margin: 0 -20px;
				overflow-x: scroll;
				scroll-snap-type: x mandatory;
				padding-bottom: 10px;

				.carousel {
					padding: 0 20px 0 0;
					display: inline-flex !important;

					.carousel-cell {
						scroll-snap-align: center;
						flex: 1 0 auto;
						margin-left: 20px;
					}
				}
			}
		}

		@extend %carousel-nav-buttons;

		@media (min-width: $scr-sm-min) {
			margin-bottom: 80px;

			--flickity-nav-size: 28px;
			--flickity-nav-svg-size: 11px;

			&.is-hidden .mobileCarouselContainer {
				height: 110px;
				background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjY2NjIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCI+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgMTggMTgiIHRvPSIzNjAgMTggMTgiIGR1cj0iMXMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9wYXRoPjwvZz48L3N2Zz4=') no-repeat center;

				.carousel {
					opacity: 0;
				}
			}

			.carousel-cell {
				margin-left: 26px;
				width: 143px;
			}

			.flickity-button.next::after,
			.flickity-button.previous::after {
				top: 0;
			}

			.flickity-prev-next-button.next {
				right: -14px;
			}

			.flickity-prev-next-button.previous {
				left: -14px;
			}

			.flickity-page-dots {
				bottom: -35px;

				.dot {
					width: 9px;
					height: 9px;
					margin: 0 5px;
					background-color: transparent;
					box-shadow: 0 0 0 1px var(--grey-800) inset;
					opacity: 1;

					&.is-selected {
						background-color: var(--grey-800);
					}
				}
			}
		}

		&.lazy-background.visible {
			a {
				background-image: url('../../../web/img/rules/other-rules-bg.png');

				@media only screen and (min-resolution: 2dppx) {
					background-image: url('../../../web/img/rules/other-rules-bg@2x.png');
				}
			}
		}

		a {
			display: grid;
			border-radius: var(--rad);
			border: 1px solid var(--grey-400);
			padding: 7px;
			background-repeat: no-repeat;
			background-size: cover;
			background-origin: content-box;
			background-position: center;
			align-items: center;
			height: 110px;
			width: 143px;


			&:hover {
				border-color: #000
			}

			b {
				text-align: center;
				display: flex;
				background-color: #fff;
				padding: 5px;
				color: #000;
				min-height: 46px;
				justify-content: center;
				align-items: center;
			}
		}

	}
}
