@use "../../designSystem/mixins/border-arrow" as *;

%carousel-nav-buttons {
    .flickity-button {
        width: var(--flickity-nav-size);
        height: var(--flickity-nav-size);
        background-color: #fff;
        border: 1px solid var(--grey-400);
        z-index: 1;

        &:hover {
            border-color: #000;
        }


        &.previous,
        &.next {
            &::after {
                position: relative;
                top: 1px;
            }
        }


        &.previous {
            &::after {
                @include border-arrow(left, var(--flickity-nav-svg-size), var(--flickity-nav-svg-size));

                left: -1px;
            }
        }

        &.next {
            &::after {
                @include border-arrow(right, var(--flickity-nav-svg-size), var(--flickity-nav-svg-size));

                left: 1px;
            }
        }

        &:disabled {
            border-color: var(--grey-200);
            opacity: 1;

            &::after {
                opacity: .3;
            }
        }

        svg {
            display: none;
        }

        &:focus {
            box-shadow: none;
        }
    }
}
